import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { WorkflowActions } from './workflow.actions';
import { Workflow } from './workflow.model';

export const workflowsFeatureKey = 'workflows';

export interface WorkflowState extends EntityState<Workflow> {
    loading: boolean;
    editorSaving: boolean;
    inputOrderUpdating: boolean;
}

export const adapter: EntityAdapter<Workflow> = createEntityAdapter<Workflow>();

export const initialState: WorkflowState = adapter.getInitialState({
    loading: false,
    editorSaving: false,
    inputOrderUpdating: false,
});

export const workflowReducer = createReducer(
    initialState,
    on(
        WorkflowActions.loadWorkflows,
        WorkflowActions.loadWorkflowById,
        WorkflowActions.generateWorkflow,
        WorkflowActions.createEmptyWorkflow,
        WorkflowActions.toggleWorkflowFavorite,
        WorkflowActions.publishWorkflowDraft,
        WorkflowActions.removeWorkflowDraft,
        WorkflowActions.deleteWorkflow,
        state => ({...state, loading: true}),
    ),
    on(
        WorkflowActions.updateWorkflowDraft,
        WorkflowActions.updateWorkflowDraftInput,
        state => ({...state, editorSaving: true, loading: true}),
    ),
    on(
        WorkflowActions.updateWorkflowDraftInputOrder,
        WorkflowActions.addWorkflowDraftInput,
        WorkflowActions.removeWorkflowDraftInput,
        state => ({...state, inputOrderUpdating: true,  loading: true}),
    ),
    on(
        WorkflowActions.loadWorkflowByIdSuccess,
        WorkflowActions.createEmptyWorkflowSuccess,
        WorkflowActions.generateWorkflowSuccess,
        WorkflowActions.updateWorkflowDraftSuccess,
        WorkflowActions.toggleWorkflowFavoriteSuccess,
        WorkflowActions.addWorkflowDraftInputSuccess,
        WorkflowActions.updateWorkflowDraftInputSuccess,
        WorkflowActions.updateWorkflowDraftInputOrderSuccess,
        WorkflowActions.removeWorkflowDraftInputSuccess,
        (state, action) =>
            adapter.upsertOne(action.workflow, {
                ...state,
                loading: false,
                editorSaving: false,
                inputOrderUpdating: false,
            }),
    ),
    on(WorkflowActions.publishWorkflowDraftSuccess, WorkflowActions.removeWorkflowDraftSuccess, (state, action) =>
        adapter.setOne(action.workflow, {
            ...state,
            loading: false,
        }),
    ),
    on(WorkflowActions.deleteWorkflowSuccess, (state, action) =>
        adapter.removeOne(action.workflow.id, {
            ...state,
            loading: false,
        }),
    ),
    on(WorkflowActions.loadWorkflowsSuccess, (state, action) =>
        adapter.setAll(action.workflows, {
            ...state,
            loading: false,
        }),
    ),
    on(
        WorkflowActions.loadWorkflowsFailure,
        WorkflowActions.loadWorkflowByIdFailure,
        WorkflowActions.generateWorkflowFailure,
        WorkflowActions.createEmptyWorkflowFailure,
        WorkflowActions.updateWorkflowDraftFailure,
        WorkflowActions.toggleWorkflowFavoriteFailure,
        WorkflowActions.publishWorkflowDraftFailure,
        WorkflowActions.deleteWorkflowFailure,
        WorkflowActions.removeWorkflowDraftFailure,
        WorkflowActions.addWorkflowDraftInputFailure,
        WorkflowActions.updateWorkflowDraftInputFailure,
        WorkflowActions.updateWorkflowDraftInputOrderFailure,
        WorkflowActions.removeWorkflowDraftInputFailure,
        state => ({
            ...state, loading: false,
            editorSaving: false,
            inputOrderUpdating: false,
        }),
    ),
);

export const workflowsFeature = createFeature({
    name: workflowsFeatureKey,
    reducer: workflowReducer,
    extraSelectors: ({selectWorkflowsState}) => ({
        ...adapter.getSelectors(selectWorkflowsState),
    }),
});
