import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { WorkflowAddDraftInputDto } from './dto/workflow-add-draft-input.dto';
import { WorkflowGenerateDto } from './dto/workflow-generate.dto';
import { WorkflowRemoveDraftInputDto } from './dto/workflow-remove-draft-input.dto';
import { WorkflowSetDraftDto } from './dto/workflow-set-draft.dto';
import { WorkflowUpdateDraftInputOrderDto } from './dto/workflow-update-draft-input-order.dto';
import { WorkflowUpdateDraftInputDto } from './dto/workflow-update-draft-input.dto';
import { WorkflowUpdateDraftDto } from './dto/workflow-update-draft.dto';

import { Workflow, WorkflowBase } from './workflow.model';

export const WorkflowActions = createActionGroup({
    source: 'Workflow/API',
    events: {
        'Generate Workflow': props<{ dto: WorkflowGenerateDto }>(),
        'Generate Workflow Success': props<{ workflow: Workflow }>(),
        'Generate Workflow Failure': props<{ error: any }>(),

        'Create Empty Workflow': emptyProps(),
        'Create Empty Workflow Success': props<{ workflow: Workflow }>(),
        'Create Empty Workflow Failure': props<{ error: any }>(),

        'Load Workflows': emptyProps(),
        'Load Workflows Success': props<{ workflows: Workflow[] }>(),
        'Load Workflows Failure': props<{ error: any }>(),

        'Load Workflow By Id': props<{ id: string }>(),
        'Load Workflow By Id Success': props<{ workflow: Workflow }>(),
        'Load Workflow By Id Failure': props<{ error: any }>(),

        'Update Workflow Draft': props<{ id: string; dto: WorkflowUpdateDraftDto }>(),
        'Update Workflow Draft Success': props<{ workflow: Workflow }>(),
        'Update Workflow Draft Failure': props<{ error: any }>(),

        'Add Workflow Draft Input': props<{ id: string; dto: WorkflowAddDraftInputDto }>(),
        'Add Workflow Draft Input Success': props<{ workflow: Workflow }>(),
        'Add Workflow Draft Input Failure': props<{ error: any }>(),

        'Update Workflow Draft Input': props<{ id: string; dto: WorkflowUpdateDraftInputDto }>(),
        'Update Workflow Draft Input Success': props<{ workflow: Workflow }>(),
        'Update Workflow Draft Input Failure': props<{ error: any }>(),

        'Update Workflow Draft Input Order': props<{ id: string; dto: WorkflowUpdateDraftInputOrderDto }>(),
        'Update Workflow Draft Input Order Success': props<{ workflow: Workflow }>(),
        'Update Workflow Draft Input Order Failure': props<{ error: any }>(),

        'Remove Workflow Draft Input': props<{ id: string; dto: WorkflowRemoveDraftInputDto }>(),
        'Remove Workflow Draft Input Success': props<{ workflow: Workflow }>(),
        'Remove Workflow Draft Input Failure': props<{ error: any }>(),

        'Remove Workflow Draft': props<{ id: string }>(),
        'Remove Workflow Draft Success': props<{ workflow: Workflow }>(),
        'Remove Workflow Draft Failure': props<{ error: any }>(),

        'Toggle Workflow Favorite': props<{ id: string }>(),
        'Toggle Workflow Favorite Success': props<{ workflow: Workflow }>(),
        'Toggle Workflow Favorite Failure': props<{ error: any }>(),

        'Publish Workflow Draft': props<{ id: string }>(),
        'Publish Workflow Draft Success': props<{ workflow: Workflow }>(),
        'Publish Workflow Draft Failure': props<{ error: any }>(),

        'Delete Workflow': props<{ id: string }>(),
        'Delete Workflow Success': props<{ workflow: Workflow }>(),
        'Delete Workflow Failure': props<{ error: any }>(),
    },
});
