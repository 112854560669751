import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { Workflow } from './+store/workflow/workflow.model';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    http = inject(HttpClient);
    socket = inject(Socket);

    loadAll() {
        return this.http.get<Workflow[]>(`${environment.apiUrl}/workflow`);
    }

    loadById(id: string) {
        return this.http.get<Workflow>(`${environment.apiUrl}/workflow/${id}`);
    }

    subscribe(id: string) {
        this.socket.emit('subscribe', `workflow/${id}`);
    }

    unsubscribe(id: string) {
        this.socket.emit('unsubscribe', `workflow/${id}`);
    }
}
